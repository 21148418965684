import { useEffect, useState, ChangeEvent, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
import './index.css'
import logo from '../../assets/logodurva.jpg'
import semImg from '../../assets/semfoto.png'
import { setupApiClient } from '../../services/api'
import { CategoriaProps } from '../Categoria'
import Loading from '../../components/Loading'
import Cardapio, { ProdutosProps } from '../Cardapio'
import { FaRegSadTear, FaShoppingCart } from 'react-icons/fa'
import Footer from '../../components/Footer'
import ModalImagem from '../../components/ModalIagem'
import { useParams, useNavigate } from 'react-router-dom'
import { RiSubtractLine } from "react-icons/ri"
import { IoMdAdd, IoMdRefresh } from "react-icons/io"
import { toast } from 'react-toastify'
import ModalCart from '../../components/ModalCart'
import ModalMeusPedidos from '../../components/ModalMeusPedidos'
import { AuthContext } from '../../contexts/AuthContext'

export interface MeuspedidosProps{
    ID: number
    PRODUTO_ID: number
    NOMEPRODUTO: string
    QUANTIDADE: number
    VALOR_UNITARIO: number
    TOTAL: number
    CONCLUIDO?: string
}

export default function DurvaRockBar(){
    const navigate = useNavigate()
    const { desabilitaInputNome } = useContext(AuthContext)
    const [categorias, setCategorias] = useState<CategoriaProps[]>([])
    const [listaProdutos, setListaProdutos] = useState<ProdutosProps[]>([])
    const [listaFiltrada, setListaFiltrada] = useState<ProdutosProps[]>([])
    const [meuspedidos, setMeusPedidos] = useState<MeuspedidosProps[]>([])
    const [meuspedidosTotal, setMeusPedidosTotal] = useState(0)
    const [loading, setLoading] = useState(true)
    const [loadPedido, setLoadPedido] = useState(false)
    const [urlImg, SetUrlImg] = useState('')
    const [modalImg, setModalImg] = useState(false)
    const [modalCart, setModalCart] = useState(false)
    const [modalpedidos, setModalPedidos] = useState(false)
    const [Id, setID] = useState('')
    const [pos, setPos] = useState(false)
    const [numpedido, setNumPedido] = useState(0)
    const [pesquisa, setPesquisa] = useState('')
    const [corCart, setCorCart] = useState('#fff')
    const { id } = useParams()

    useEffect( () => {
        navigate('/durvarockbar')

        const interval = setInterval( () => {
            if(localStorage.getItem('@MBlocalidmesa')){
                deleteCart()
            }
            setID('')
            localStorage.removeItem('@MBlocalidmesa')
            handleClear()
            clearInterval(interval)
        }, 1000 * 60 * 5)
    },[Id])

    useEffect( () => {
        async function GetCategorias(){
            try{
                if(id){
                    setID(id)
                    localStorage.setItem('@MBlocalidmesa',id)

                    if(!localStorage.getItem('@uuidClientelocal')){
                        let uuidlocal = uuidv4()
                        localStorage.setItem('@uuidClientelocal', uuidlocal)
                    } 
                }

                const apiClient = setupApiClient()
                const respCategoria = await apiClient.get('/categoriasCardapio',{
                    params:{
                        empresa: process.env.REACT_APP_CARD_DURVA
                    }
                })

                handleClear()
                setCategorias(respCategoria.data)
                setLoading(false)
            } catch(e){
                console.log(e)
                setCategorias([])
                setLoading(false)
            }
        }

        GetCategorias()   
    },[])

    useEffect(() => {
        async function GetProdutos(){
            try{
                const apiClient = setupApiClient()
                const respProdutos = await apiClient.get('/produtosCardapio',{
                    params:{
                        empresa: process.env.REACT_APP_CARD_DURVA,
                        cardapio: 'S'
                    }
                })                

                setListaProdutos(respProdutos.data)
                setLoading(false)
            } catch(e){
                console.log(e)
                setCategorias([])
                setLoading(false)
            }
        }

        GetProdutos()          
    },[categorias])

    useEffect( () => {
        let arrItens: ProdutosProps[] = []

        arrItens = listaProdutos.filter( (prod) => prod?.CODCATEGORIA == parseInt(categorias[0].ID))
        setListaFiltrada(arrItens)

    },[listaProdutos, pos])

    async function deleteCart(){
        try{
            const apiClient = setupApiClient()
            await apiClient.delete('/dadoscart',{
                params:{
                    empresa: process.env.REACT_APP_CARD_DURVA,
                    uuid: localStorage.getItem('@uuidClientelocal'),
                    mesa: localStorage.getItem('@MBlocalidmesa')
                },
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

        } catch(e){
            console.log(e)
        }
    }

    function handleCategoria(e:any, id:string){
        let btn = document.getElementsByClassName('btn-card')

        for (let i = 0; i < btn.length; i++) {
            btn[i].className = btn[i].className.replace(' active', '');
        }

        e.currentTarget.className += ' active' 

        let arrItens: ProdutosProps[] = []

        arrItens = listaProdutos.filter( (prod) => prod?.CODCATEGORIA == parseInt(id))
        setListaFiltrada(arrItens)
      
    }

    function handleImg(url: string){
        if(!url){
            return
        }
        SetUrlImg(url)
        setModalImg(!modalImg)
    }

    function handleRem(index: number){
        const value = document.getElementById(`qt${index}`).innerHTML
        let newvalue = parseInt(value) - 1
        
        if(newvalue < 1){
            newvalue = 1
        }
        document.getElementById(`qt${index}`).innerText = String(newvalue)
    }

    function handleAdd(index: number){
        const value = document.getElementById(`qt${index}`).innerHTML
        let newvalue = parseInt(value) + 1 
        document.getElementById(`qt${index}`).innerText = String(newvalue)
    }

    async function handleAddCart(p: ProdutosProps, index: number){

        let qtd = document.getElementById(`qt${index}`).innerText
        document.getElementById(`qt${index}`).innerText = '1'

        if(!localStorage.getItem('@uuidClientelocal')){
            toast.error('Id não encontrado, verifique se os cookies não estão bloqueados!')
            return
        }
        
        try{
            let obj = {
                empresa: process.env.REACT_APP_CARD_DURVA,
                idmesa: Id,
                uuid: localStorage.getItem('@uuidClientelocal'),
                totalpedido: parseFloat(p.PRECO) * parseFloat(qtd),
                codigoprod: p.CODPRODUTO,
                nomeprod: p.NOMEPRODUTO,
                quant: parseFloat(qtd),
                valor: parseFloat(p.PRECO),
                total: parseFloat(p.PRECO) * parseFloat(qtd)                
            }

            setLoading(true)
            const apiClient = setupApiClient()
            const respTemp = await apiClient.post('/pedidomesa', obj,{
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

            if(respTemp.data?.COD == 409){
                toast.error(respTemp.data?.MSG)
                setID('')
                setNumPedido(0)
                localStorage.removeItem('@MBlocalidmesa')
                handleClear()
                setLoading(false)
                return                  
            }

            if(respTemp.data?.COD == 452){
                toast.warn(respTemp.data?.MSG)
                desabilitaInputNome(false)
                setNumPedido(0)
                localStorage.removeItem('@nomeCliBar')
                document.getElementById('idnomeconsumidor').focus()
                setLoading(false)
                return                  
            }

            if(respTemp.data.COD == 201){
                setNumPedido(respTemp.data?.ID)
                const produtos = document.getElementsByClassName('itemcard')
                for(let i=0; i < produtos.length; i++){
                    produtos[i].classList.remove('ocultarLinha')   
                }
                setPos(!pos)
                setPesquisa('')
                setCorCart('#C8011E')
                toast.success('Item adicionado ao carrinho!')
                setLoading(false)
            } else {
                toast.error('Erro adicionando item ao carrinho!')
                setLoading(false)
            }

        } catch(e){
            console.log(e)
            setLoading(false)
        }
    }

    function handleOpenCart(){
        if(corCart == '#C8011E'){
            setModalCart(true)
        }
    }

    async function handleClear(){
        deleteCart()  
        setNumPedido(0)  
        setModalCart(false)
        setModalPedidos(false)
        setCorCart('#fff')
    }

    function PesquisaProduto(e: ChangeEvent<HTMLInputElement>){
        let filtrar: string, texto: string
        const produtos = document.getElementsByClassName('itemcard')

        filtrar = e.target.value.toUpperCase()
        setPesquisa(filtrar)

        for(let i=0; i < produtos.length; i++){
            texto = produtos[i].children[1].children[0].innerHTML

            if(texto.toUpperCase().indexOf(filtrar) > -1){
                produtos[i].classList.remove('ocultarLinha')
            } else {
                produtos[i].classList.add('ocultarLinha')  
            }
        }
    }

    async function handleMeusPedidos(){
        try{
            setLoadPedido(true)
            const apiClient = setupApiClient()
            const respPedidos = await apiClient.get('/meuspedidomesa',{
                params:{
                    empresa: process.env.REACT_APP_CARD_DURVA,
                    idmesa: Id,
                    uuid: localStorage.getItem('@uuidClientelocal') 
                },
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

            if(respPedidos.data.length > 0){
                let total = 0
                setMeusPedidos([])
                let itens: any[] = []

                respPedidos.data.map( (p: any) => {
                    total += p.TOTAL
                
                    itens.push({
                        ID: p?.PRODUTO_ID,
                        PRODUTO_ID: p?.PRODUTO_ID,
                        NOMEPRODUTO: p?.NOMEPRODUTO,
                        QUANTIDADE: p?.QUANTIDADE,
                        VALOR_UNITARIO: p?.VALOR_UNITARIO,
                        TOTAL: p?.TOTAL,
                        CONCLUIDO: p?.CONCLUIDO
                    })
                
                })

                let newItens = []
                itens.map( (item: any) => {
                    const idx = newItens.findIndex(p => p?.ID === item.ID)
    
                    if (idx != -1){
                        newItens[idx].QUANTIDADE += item?.QUANTIDADE
                        newItens[idx].TOTAL = newItens[idx].VALOR_UNITARIO * newItens[idx].QUANTIDADE 
                    } else {
                        newItens.push({
                            ID: item?.PRODUTO_ID,
                            PRODUTO_ID: item?.PRODUTO_ID,
                            NOMEPRODUTO: item?.NOMEPRODUTO,
                            QUANTIDADE: item?.QUANTIDADE,
                            VALOR_UNITARIO: item?.VALOR_UNITARIO,
                            TOTAL: item?.TOTAL,
                            CONCLUIDO: item?.CONCLUIDO  
                        })
                    }
                })

                setMeusPedidosTotal(total)
                setMeusPedidos(newItens)
                setModalPedidos(true)
            } else{
                toast.warn('Nenhum pedido encontrado!')
            }
            
            setLoadPedido(false)
        } catch(e){
            console.log(e)
            setMeusPedidos([])
            setLoadPedido(false)
            setModalPedidos(false)
        }        
    }

    async function handleEnviarPedido(nome: string, obs?: string){
        if(!localStorage.getItem('@MBlocalidmesa')){
            toast.warn('Faça leitura do qrcode novamente!')
            return
        }

        if(numpedido == 0){
            toast.warn('Pedido não encontrado!')
            return            
        }

        try{
            let pedido = {
                empresa: process.env.REACT_APP_CARD_DURVA,
                idmesa: Id,
                pedido: numpedido,
                nome: nome == '' ? 'CONSUMIDOR' : nome.toUpperCase(),
                uuid: localStorage.getItem('@uuidClientelocal'),
                obs: obs
            }
   
            const apiClient = setupApiClient()
            const response = await apiClient.put('/pedidomesa',pedido,{
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

            if(response.data?.COD == 200){
                localStorage.setItem('@nomeCliBar', nome.toUpperCase())
                toast.success('Pedido confirmado!')
                handleClear()
            } else {
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return  
            }
        } catch(e) {
            console.log(e)
            toast.error('erro confirmando pedido!')
            return
        }
    }

    // if(fechado){
    //     return(
    //         <main className='main-fechado'>
    //             <FaRegSadTear size={80} color='#ddd'/>
    //             <span>Ops, o estabelecimento está fechado!</span>
    //         </main>
    //     )        
    // }

    if(loading){
        return(
            <main>
                <Loading/>
            </main>
        )
    } 

    return(
        <main className='container-card' >
            <header className='topo-card'>
                <img src={logo} alt='DurvaRockBar' width={200} height={120} />
                <div className='texto-card'>
                    <span>Cardápio</span>
                    <span>Durva <span style={{color:'yellow'}}>Rock Bar</span></span>
                    {Id &&(
                        <div className='div-finalizar'>
                            <button onClick={handleMeusPedidos}>
                                {loadPedido ? <IoMdRefresh size={18} color='#FFF'/> : 'Meus pedidos'}    
                            </button>
                            <FaShoppingCart color={corCart} size={25} onClick={handleOpenCart}/>
                        </div>
                    )}
                </div>
            </header>
            <div className='obs'>
                <span>Cervejas Artesanais e Importadas solicitar no balcão!</span>
                <input 
                    type="search" 
                    placeholder='Pesquisar produtos...' 
                    value={pesquisa}
                    onChange={(e) => PesquisaProduto(e)}
                />
            </div>           
            <nav className='menu-card'>
                { categorias.map( (categoria, index) => {
                    return(
                        <button key={index} className={`btn-card ${index == 0 ? ' active' : ''}`} onClick={(e) => handleCategoria(e, categoria?.ID)}>
                            {categoria?.CATEGORIA}
                        </button>
                    )
                })}
            </nav>
            <section className='itens-card'>
                { listaFiltrada.length <= 0 && (
                    <div className='div-empty'>
                        <FaRegSadTear size={80} color='#bbb'/>
                        <span>Não encontrei nada...</span>    
                    </div>
                )}
                { listaFiltrada.map( (prod, index) => {
                    return(
                        <div key={index} className='itemcard'>
                            <div className='imgprod'>
                                <img src={prod?.URL ? prod?.URL : semImg} alt="Img" onClick={() => handleImg(prod?.URL)} />
                            </div>     
                            <div className='containerProd'>
                                <div className='dados-prod'>
                                    <h5>{prod?.NOMEPRODUTO}</h5>
                                    <span>{prod?.DESCRICAO}</span>
                                    <div className='div-valor'>
                                        <span>{prod?.PRECO != '' ? parseFloat(prod?.PRECO).toLocaleString('pt-BR',{style:'currency', currency:'BRL'}) : 'R$ 0,00' }</span>  
                                    </div>
                                </div>
                                {Id && (
                                    <div className='container-add'>
                                        <button className='btnQtd menos' onClick={() => handleRem(index)}>
                                            <RiSubtractLine size={30}/>
                                        </button>
                                        <span id={`qt${index}`}>1</span>
                                        <button className='btnQtd mais' onClick={() => handleAdd(index)}>
                                            <IoMdAdd size={25} color='#fff'/>
                                        </button>
                                        <button className='btnAdd' onClick={() => handleAddCart(prod, index)}>
                                            Adicionar
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}

            </section>
            <Footer/>
            
            {modalImg && (
                <ModalImagem
                    url={urlImg}
                    onClose={() => setModalImg(!modalImg)}
                />
            )}

            {modalpedidos && (
                <ModalMeusPedidos
                    data={meuspedidos}
                    total={meuspedidosTotal}
                    onClose={() => setModalPedidos(false)}
                    
                />
            )}

            {modalCart && (
                <ModalCart
                    cardapio={process.env.REACT_APP_CARD_DURVA}
                    onClose={() => setModalCart(false)}
                    onClear={handleClear}
                    onSubmit={(nome, obs) => handleEnviarPedido(nome, obs)}
                />
            )}
            
        </main>
    )
}