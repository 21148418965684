import './index.css'
import { MeuspedidosProps } from "../../pages/CardapioDurva"
import { IoCloseCircle } from "react-icons/io5"
import { useState } from 'react'

interface modalPedidoProps{
    data: MeuspedidosProps[]
    total: number
    onClose: () => void
}

export default function ModalMeusPedidos({ data, total, onClose }: modalPedidoProps){
    const [prep, setPrep] = useState('')
    
    return(
        <div className='containerModal'>
            <div className='containerCart' onClick={onClose}>
                <div className='container-itens-pedido'>
                    <div className='div-menu'>
                        <span>Meus pedidos</span>
                    </div>
                    { data.map( (item, index) => {
                        if(prep != 'S'){
                            item?.CONCLUIDO == 'X' && setPrep('S')
                        }
                        return(
                            <div key={index} className='div-itens-cart'>
                                <div className='div-nomeitem'>
                                    <span>{item.NOMEPRODUTO}</span>
                                </div>
                                <div className='div-valores'>
                                    <span>{item.QUANTIDADE}</span>
                                    <span>X</span>
                                    <span>{item.VALOR_UNITARIO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                    <span>=</span>
                                    <span>{item.TOTAL.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>                                        
                                </div>
                            </div>
                        )
                    })}
                </div> 
                <div className='div-total-ped'>
                    <button onClick={onClose}>
                        <IoCloseCircle size={40} color='#C8011E' />
                    </button>
                    <span>TOTAL {total.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>    
                </div> 
                { prep == 'S' && (
                    <span className='sp-txt-preparacao'>Existe pedido em preparação</span>
                )}
            </div> 
        </div>
    )
}