import { useState, useEffect, useContext } from 'react'
import './index.css'
import { FaWindowClose } from 'react-icons/fa'
import { Button }from '../../components/Button'
import { TotalNomeProps, FechamentoMesaProps } from '../../pages/Mesas'
import { TiPrinter } from 'react-icons/ti'
import { AuthContext } from '../../contexts/AuthContext'
import logo from '../../assets/logocomanda.jpg'

export interface ValoresCaixaProps{
    mesa: number
    total: number
    forma: string
}

export interface ItensPrintProps{
    ID: string
    NOME: string
    QUANT: number
    UNITARIO:  string,
    TOTAL: string
}

interface ModalFechamentoProps{
    onClose: () => void
    onRequest: (dados: ValoresCaixaProps) => Promise<void>
    data: FechamentoMesaProps
    totalPorNome: TotalNomeProps[] 
}

export default function ModalFechamento({ onClose, onRequest, data, totalPorNome }: ModalFechamentoProps){
    const { user } = useContext(AuthContext)
    const dt = new Date().getDate()
    const mes = new Date().getMonth() + 1 
    const ano = new Date().getFullYear()
    const hora = new Date().getHours().toLocaleString('pt-BR')
    const min = new Date().getMinutes().toLocaleString('pt-BR')

    const [pagamento, setPagamento] = useState(['DINHEIRO','CHEQUE','CARTÃO DE CRÉDITO','CARTÃO DE DÉBITO','PIX'])
    const [pagamentoSelecionado, setPagamentoSelecionado] = useState(0)
    const [totalItens, setTotalItens] = useState(0)
    const [atendimento, setAtendimento] = useState(0)
    const [totalPapar, setTotalPagar] = useState(0)
    const [incluiTaxa, setIncluiTaxa] = useState(false)
    
    useEffect( () => {
        let taxa = 0;

        if(incluiTaxa){
            taxa = data?.TOTALMESA * 10 / 100
        }
        
        setAtendimento(taxa)
        setTotalItens(data?.TOTALMESA)
        setTotalPagar(data?.TOTALMESA + taxa)
    },[data, incluiTaxa])

    
    function handlePagamentoSelecionado(e){
        setPagamentoSelecionado(e.target.value)
    }

    function handleImprimir(){
        window.print()
    }

    return(
        <div className='containerModal'>
            <div className='contentModalDetalhe altura'>
                <div className='btnCloseModal fechamento'>
                    <button onClick={onClose}>
                        <FaWindowClose size={20} color='#FFF'/>
                    </button>
                    <h4>Mesa {data?.MESA}</h4>
                </div>

                <div className='container-itens'>
                    { data?.ITENS.map( (item, index) => {
                        return(
                            <ul key={index} className='itensmesa'>
                                <li key={index}>
                                    <span>{item?.NOME} </span>
                                    <i>{item?.QUANT.toFixed(2)} x {parseFloat(item?.UNITARIO).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})} = {parseFloat(item?.TOTAL).toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</i>
                                </li>
                            </ul>
                        )
                    })}
                </div>

                <div className='pagamentos'>
                    <span>Forma de pagamento</span>
                    <select value={pagamentoSelecionado} onChange={handlePagamentoSelecionado}>
                        {pagamento.map( (item, index) => {
                            return(
                                <option key={index} value={index}>
                                    {item}
                                </option>
                            )
                        })}
                    </select>

                    <div className='cont-valores'>
                        <div className='div-nomesMesa'>
                            {totalPorNome.map( (nome, index) => {
                                return(
                                    <div key={index} className='nomes-mesa'>
                                        <i>{nome.NOME}</i>
                                        <i>{nome.TOTAL.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</i>
                                    </div>
                                )
                            })}
                        </div>
                        <div className='valores'>
                            <h4>TOTAL - {data?.TOTALMESA.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</h4>
                            <h4>TAXA 10% - {atendimento.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</h4>
                            <h3>TOTAL A PAGAR - {totalPapar.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</h3>
                        </div>
                    </div>

                    <div className='checkAtendimento'>
                        <input type='checkbox' id='taxa' checked={incluiTaxa} onChange={ () => setIncluiTaxa(!incluiTaxa) }/>
                        <label htmlFor="taxa">Incluir taxa de atendimento</label>
                    </div>
                </div>

                <div className='btnFecharMesa'>
                    <button 
                        id='btnImpComanda'
                        onClick={handleImprimir}
                    >
                        <TiPrinter size={25} color='#FFF'/>
                        Imprimir comanda
                    </button>        

                    <Button onClick={() => onRequest({
                            mesa: data?.MESA,
                            forma: pagamento[pagamentoSelecionado],
                            total: incluiTaxa ? totalPapar : totalItens
                    })}>
                        Fechar mesa
                    </Button>
                </div>
             </div>

            <div className='printComanda'>
                <div className='printNome'>
                    {/* <img src={logo} alt="Logo" width={80} height={30} /> */}
                    <h4>{user?.name}</h4>
                    <h5>{dt < 9 ? `0`+dt : dt}/{mes < 9 ? `0`+mes : mes}/{ano}  {hora}:{parseInt(min) < 9 ? '0'+min : min}</h5>    
                </div>

                <div className='printHeaderMesa'>
                    <h5>MESA - {data?.MESA}</h5>
                </div>

                {data?.ITENS.map( (item, index) => {
                    return(
                        <div key={index} className='printDetailMesa'>
                            <h4>{item?.NOME}</h4>
                            <span>QTD: {item?.QUANT.toFixed(2)} X R$ {parseFloat(item?.UNITARIO).toFixed(2)} = R$ {parseFloat(item?.TOTAL).toFixed(2)}</span>
                        </div>
                    )
                })}

                <div className='printSubTotal'>
                    <span id='idsubtotal'>SUBTOTAL: R$ {data?.TOTALMESA.toFixed(2)}</span>
                    <span id='idtaxa'>TAXA 10%: R$ {atendimento.toFixed(2)}</span>
                    <span id='idtotal'>TOTAL: R$ {totalPapar.toFixed(2)}</span>
                </div>

                <div className='printrodape'>
                    <h5>Sistema MeuBar</h5>
                </div>
            </div>
        </div>
    )
}