import { useEffect, useState } from 'react'
import Header from "../../components/Header"
import Footer from '../../components/Footer'
import './index.css'
import { Button } from "../../components/Button"
import { Input } from "../../components/Input"
import Loading from '../../components/Loading'
import { FaCashRegister } from 'react-icons/fa'
import { setupApiClient } from '../../services/api'
import { toast } from 'react-toastify'
import Modal, { ItemProps } from '../../components/Modal'

interface FluxoCaixaProps{
    ID: number
    DATA: Date
    FORMAPAGTO: string
    DESCRICAO: string
    CREDITO: number
    DEBITO: number
}

export default function Caixa(){
    const [loading, setLoading] = useState(true)
    const [fluxoCaixa, setFluxoCaixa] = useState<FluxoCaixaProps[]>([])
    const [saldoCaixa, setSaldoCaixa] = useState(0)
    const [valor, setValor] = useState('')
    const [descricao, setDescricao] = useState('')
    const [refresh, setRefresh] = useState(false)

    const [dadosModal, setDadosModal] = useState<ItemProps>()
    const [openModal, setOpenModal] = useState(false)

    useEffect( () => {
        async function getListCaixa(){
            try{
                const apiCliente = setupApiClient()

                const response = await apiCliente.get('/caixa',{
                    params:{
                        empresa: sessionStorage.getItem('@id_meu_bar')
                    }
                })

                setFluxoCaixa(response.data) 
                setDadosModal({
                    ID: response.data?.ID,
                    CATEGORIA:'Essa operação é irreversível!'
                })
                setLoading(false)
            } catch {
                setLoading(false)
                toast.error('Erro buscando dados do caixa!')
            }
        }

        getListCaixa()
    },[refresh])

    useEffect( () => {
        try{
            let credito = 0
            let debito = 0
            fluxoCaixa.map( (item) => {
                credito += item?.CREDITO ? item?.CREDITO : 0
                debito += item?.DEBITO ? item?.DEBITO : 0                
            })

            setSaldoCaixa(credito - debito)
        } catch {
            setSaldoCaixa(0)
        }

    },[fluxoCaixa])

    async function handleCredito(){
        if(valor === '' || descricao === ''){
            toast.warn('Preencha os campos para lançar!')
            return
        }

        try{
            setLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.post('/caixamanual',{
                empresa: sessionStorage.getItem('@id_meu_bar'),
                tipo: 'E',
                formapagto: 'ENTRADA-MANUAL',
                descricao: descricao.toUpperCase(),
                valor: valor               
            })

            if(response.data?.COD == 201){
                setValor('')
                setDescricao('')
                setRefresh(!refresh)
                setLoading(false)
                toast.success('Entrada creditada com sucesso!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro lançando o crédito!')
            setLoading(false)
            return
        }
    }

    async function handleDebito(){
        if(valor === '' || descricao === ''){
            toast.warn('Preencha os campos para lançar!')
            return
        }

        try{
            setLoading(true)
            const apiClient = setupApiClient()

            const response = await apiClient.post('/caixamanual',{
                empresa: sessionStorage.getItem('@id_meu_bar'),
                tipo: 'S',
                formapagto: 'SAÍDA-MANUAL',
                descricao: descricao.toUpperCase(),
                valor: valor               
            })

            if(response.data?.COD == 201){
                setValor('')
                setDescricao('')
                setRefresh(!refresh)
                setLoading(false)
                toast.success('Saída creditada com sucesso!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro lançando o crédito!')
            setLoading(false)
            return
        }
    }

    function handleFecharCaixa(){
        if(fluxoCaixa.length === 0 ){
            return
        }
        setOpenModal(true)
    }
    
    async function handleReqFecharCaixa(){
        try{
            const apiClient = setupApiClient()

            const response = await apiClient.post('/fecharcaixa',{
                empresa: sessionStorage.getItem('@id_meu_bar')
            })

            if(response.data?.COD == 200){
                setOpenModal(false)
                setRefresh(!refresh)
                toast.success('Caixa fechado!')
            } else {
                setLoading(false)
                toast.error(response.data?.COD+' - '+response.data?.MSG)
                return 
            }
        } catch(e) {
            console.log(e)
            toast.error('Erro fechando o caixa!')
            setOpenModal(false)
            return
        }
    }

    if(loading){
        return(
            <main>
                <Header
                    menu={5}
                />
                <Loading/>
                <Footer/>
            </main>
        )
    }

    return(
        <main onContextMenu={(e) => e.preventDefault()}>
            <Header
                menu={5}
            />
            <section className='containerProduto'>

                <div className='menuCad estilocaixa'>
                    <h2>Saldo {saldoCaixa.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</h2>

                    <Button onClick={handleFecharCaixa}>
                        <FaCashRegister size={15} color='#FFF'/>
                        Fechar caixa
                    </Button>
                </div>

                <div className='lancarvalores'>
                    <Input
                        id='valor'
                        type='text'
                        placeholder='R$ crédito/débito'
                        value={valor}
                        onChange={ (e) => setValor(e.target.value)}
                        required
                    />

                    <Input
                        id='desc'
                        type='text'
                        placeholder='Descrição'
                        value={descricao}
                        onChange={ (e) => setDescricao(e.target.value)}
                        required
                    />
                    
                    <Button id='cred' onClick={handleCredito}>
                        Crédito
                    </Button>

                    <Button id='deb' onClick={handleDebito}>
                        Débito
                    </Button>
                </div>

                <div className='tablecaixa'>
                    { fluxoCaixa.length === 0 ? (
                        <div className='caixavazio'>
                            <h2>Não existe lançamentos!</h2>
                        </div>
                    ) : (
                    <table>
                        <thead>
                            <tr className='header'>
                                <th style={ { width:'200px' } } scope="col">PAGAMENTO</th>
                                <th style={ { width:'300px' } } scope="col">DESCRIÇÃO</th>
                                <th style={ { width:'120px' } } scope="col">CRÉDITO</th>
                                <th style={ { width:'120px' } } scope="col">DÉBITO</th>
                            </tr>
                        </thead>
                        <tbody>
                        { fluxoCaixa.length > 0 && fluxoCaixa.map( (item, index) => {
                            return(
                            <tr key={index}>
                                <td data-label="Forma">{item?.FORMAPAGTO}</td>
                                <td data-label="Descricao">{item?.DESCRICAO}</td>
                                <td data-label="Credito" style={ {textAlign:'right'} } >
                                    {item?.CREDITO > 0 ? `${item?.CREDITO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}` : ''} 
                                </td>
                                <td data-label="Debito" style={ {textAlign:'right', color: item?.FORMAPAGTO === 'RETIRADA' ? '#ED1C24' : '#fff'} } >
                                    {item?.DEBITO > 0 ? `${item?.DEBITO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}` : ''} 
                                </td>
                            </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    )}
                </div>
            
                { openModal && (
                    <Modal
                        titulo='Fechar caixa?'
                        titulobtn='Fechar'
                        descricao={dadosModal}
                        onClose={ () => setOpenModal(false)}
                        onRequest={ (id) => handleReqFecharCaixa()}
                    />
                )}

                <Footer/>
            </section>
    </main>
    )
}