import './index.css'

export default function Footer(){
    return(
        <section className='footer'>
            <div className='version'>
                <h3>Imagens meramente ilustrativas</h3>
                <h3 title='20/12/2024'><a href="https://site.wgainformatica.com.br" target='_blank'>WGA Informática®</a> - Versão 1.0.3.7</h3>
            </div>
        </section>
    )
}