import './index.css'
import { GiBeerStein } from "react-icons/gi"

export default function Loading(){
    return(
        <div className='loading'>
            <GiBeerStein size={80} color='#ff0'/>
            <h4>Aguarde...</h4>
        </div>  
    )
}