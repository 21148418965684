import './index.css'
import { useEffect, useState, useContext } from 'react'
import { TbArrowBackUp } from "react-icons/tb"
import { FaCheck, FaTrash, FaTrashAlt  } from "react-icons/fa"
import { toast } from 'react-toastify'
import { AuthContext } from '../../contexts/AuthContext'
import { setupApiClient } from '../../services/api'

interface DadosCartProps{
    EMPRESA: number
    MESA: number
    PRODUTO_ID: number
    NOMEPRODUTO: string
    QUANTIDADE: number,
    VALOR_UNITARIO: number
    TOTAL: number
}

interface cartProps{
    cardapio: string
    onClose: () => void;
    onClear: () => void
    onSubmit: (nome: string, obs?: string) => void
}

export default function ModalCart({ cardapio, onClose, onClear, onSubmit }: cartProps){
    const { inputNome, desabilitaInputNome } = useContext(AuthContext)
    const [nome, setNome] = useState('')
    const [obs, setObs] = useState('')
    const [ok, setOk] = useState(true)
    const [dataCart, setDataCart] = useState<DadosCartProps[]>([])
    const [total, setTotal] = useState(0)

    setTimeout(() => {
        setOk(false)
    }, 3000);

    useEffect( () => {
        let name = localStorage.getItem('@nomeCliBar')
        if(name){
            setNome(name)
            desabilitaInputNome(true)
        } else {
            setNome('')
            desabilitaInputNome(false)
        }

        async function getDadosCart(){
            try{
                const apiClient = setupApiClient()
                const response = await apiClient.get('/dadoscart',{
                    params:{
                        empresa: cardapio,
                        uuid: localStorage.getItem('@uuidClientelocal'),
                        mesa: localStorage.getItem('@MBlocalidmesa')
                    },
                    headers:{
                        Authorization: 'Basic Auth'
                    },
                    auth:{
                        username: process.env.REACT_APP_USER,
                        password: process.env.REACT_APP_PASS
                    }
                })

                let tot = 0
                if(response.data.length > 0){
                    response.data.map( (p: DadosCartProps) => {
                        tot += p.TOTAL
                    })
                }

                setTotal(tot)
                setDataCart(response.data)
            } catch(e){
                console.log(e)
                setDataCart([])
            }
        }

        getDadosCart()
    },[])

    async function handleDelete(prod: DadosCartProps){
        try{
            const apiClient = setupApiClient()
            const response = await apiClient.delete('/dadoscart/item',{
                params:{
                    empresa: cardapio,
                    uuid: localStorage.getItem('@uuidClientelocal'),
                    mesa: localStorage.getItem('@MBlocalidmesa'),
                    produto_id: prod?.PRODUTO_ID
                },
                headers:{
                    Authorization: 'Basic Auth'
                },
                auth:{
                    username: process.env.REACT_APP_USER,
                    password: process.env.REACT_APP_PASS
                }
            })

            if(response.data?.COD == 200){
                let newCart: any
                newCart = dataCart.filter(p => p?.PRODUTO_ID != prod?.PRODUTO_ID)
                
                if(newCart.length <= 0){
                    onClear()
                } else {
                    setTotal(total - prod?.TOTAL)
                    setDataCart(newCart)
                }
            } else {
                toast.error('Erro ao excluir o item!')
            }

        } catch(e){
            console.log(e)
        }
    }

    function handleFinalizar(){
        if(nome == '' || nome == undefined || nome == null){
            toast.error('Seu nome é obrigatório') 
            desabilitaInputNome(false)
            return
        }

        if(!localStorage.getItem('@uuidClientelocal')){
            toast.error('Id não encontrado, verifique se os cookies não estão bloqueados!')
            return
        }

        onSubmit(nome, obs)
    }

    return(
        <div className='containerModal'>
            <div className='containerCart'>
                <div className='container-item'>
                    { dataCart.length > 0 && dataCart.map( (item, index) => {
                        return(
                            <div key={index} className='div-itens-cart'>
                                <div className='div-nomeitem'>
                                    <span>{item.NOMEPRODUTO}</span>
                                </div>
                                <div className='div-valores'>
                                    <span>{item.QUANTIDADE}</span>
                                    <span>X</span>
                                    <span>{item.VALOR_UNITARIO.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                    <span>=</span>
                                    <span>{item.TOTAL.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                                    <FaTrash size={25} color='#C8011E' onClick={() => handleDelete(item)} />
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='div-total'>
                    <span>TOTAL {total.toLocaleString('pt-BR',{style:'currency', currency:'BRL'})}</span>
                    <div className='div-nomecliente'>
                        <label>NOME</label>
                        <input 
                            type="text" 
                            id='idnomeconsumidor'
                            required
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                            disabled={inputNome}
                            placeholder='Seu nome completo...'
                        />
                    </div>
                    <div className='div-obs'>
                        <label htmlFor="obs">OBS</label>
                        <textarea 
                            name="obs" 
                            id="txt-obs"
                            value={obs}
                            onChange={(e) => setObs(e.target.value)}
                        />
                    </div>
                </div>
                <div className='div-btn-cart'>
                    <button className='btn-cart btn-voltar' onClick={onClose}>
                        <TbArrowBackUp size={20} color='#000'/>Voltar
                    </button>
                    <button className='btn-cart' onClick={onClear}>
                        <FaTrashAlt size={18} color='#000' /> Limpar
                    </button>
                    <button 
                        className='btn-cart btn-finalizar' 
                        onClick={handleFinalizar}
                        disabled={ok}
                    >
                        <FaCheck size={20} color='#fff'/>{ok ? 'Aguarde' : 'Finalizar'}
                    </button>
                </div>

            </div>    
        </div>
    )
}